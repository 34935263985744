<template>
  <form @submit.prevent="save">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">{{title}}</p>
        <button
          type="button"
          class="delete"
          @click="$emit('close')"/>
      </header>
      <section class="modal-card-body">
        <b-field label="Label">
          <b-input
            type="text"
            v-model="labelData"
            placeholder="Label"
            required />
        </b-field>
        <b-field label="Code" message="Code must be unique">
          <b-input
            type="text"
            v-model="codeData"
            placeholder="Code"
            required />
        </b-field>
      </section>
      <footer class="modal-card-foot buttons is-right">
        <b-button @click="$emit('close')"
          >Cancel</b-button
        >
        <b-button native-type="submit" type="is-primary" :disabled="!hasInfoChanges"
          >{{buttonLabel}}</b-button>
      </footer>
    </div>
  </form>
</template>

<script>
export default {
  name: 'AddEditBin',
  props: {
    isEdit: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Add Lookup'
    },
    label: {
      type: String,
      default: ''
    },
    code: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      labelData: this.label,
      codeData: this.code
    }
  },
  methods: {
    save () {
      if (this.hasInfoChanges) {
        this.$emit('onSave', {
          data: {
            label: this.labelData,
            code: this.codeData
          },
          close: () => { this.$emit('close') }
        })
      }
    }
  },
  computed: {
    hasInfoChanges () {
      return this.labelData !== this.label || this.codeData !== this.code
    },
    buttonLabel () {
      return this.isEdit ? 'Update' : 'Add'
    }
  }
}
</script>
